/** @jsx jsx */
import { jsx, Heading, Text, useThemeUI, Grid } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import { Box } from "theme-ui"
import { ContentNode } from "../data/content"
import { ImageCard } from "./atoms"
import { useLocalisedContent } from "./featured"
import { Link, LiteralLink } from "./nav"
import { Dot } from "./content-page"
import { TwitterHandle } from "./handles"

const PEOPLE_QUERY = graphql`
  query PeopleQuery {
    council: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//people//" }
        frontmatter: {
          organisations: {
            elemMatch: {
              frontmatter: {
                title: { regex: "/Progressive International Council/" }
              }
            }
          }
        }
      }
      sort: { fields: [frontmatter___lastName], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            twitter
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 66) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            organisations {
              frontmatter {
                title
              }
            }
            countries
          }
          fields {
            translations {
              childMarkdownRemark {
                ...TranslationFragment
              }
            }
          }
        }
      }
    }

    emeritus: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//people//" }
        frontmatter: {
          organisations: {
            elemMatch: {
              frontmatter: {
                title: { regex: "/Progressive International Emeritus Council/" }
              }
            }
          }
        }
      }
      sort: { fields: [frontmatter___lastName], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            twitter
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 66) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            organisations {
              frontmatter {
                title
              }
            }
            countries
          }
          fields {
            translations {
              childMarkdownRemark {
                ...TranslationFragment
              }
            }
          }
        }
      }
    }
  }
`

export const useCouncilStaff = () => {
  const res = useStaticQuery(PEOPLE_QUERY)
  const { council, emeritus } = res

  const councilRes = council.edges.map(e => e.node) as ContentNode[]
  const emeritusRes = emeritus.edges.map(e => e.node) as ContentNode[]

  return { councilRes, emeritusRes }
}

export const EmeritusGrid = () => {
  const { emeritusRes } = useCouncilStaff()

  return (
    <Grid gap={4} columns={[1, 2, 3]}>
      {emeritusRes.map((person, i) => {
        return (
          <Box key={i}>
            <Person key={person.id} node={person} />
          </Box>
        )
      })}
    </Grid>
  )
}

export const PeopleGrid = () => {
  const { councilRes } = useCouncilStaff()

  return (
    <Grid gap={4} columns={[1, 2, 3]}>
      {councilRes.map((person, i) => {
        return (
          <Box key={i}>
            <Person key={person.id} node={person} />
          </Box>
        )
      })}
    </Grid>
  )
}

const Person: React.FC<{ node: ContentNode }> = ({ node }) => {
  const localisedContent = useLocalisedContent(node)
  const { theme } = useThemeUI()
  const {
    countries,
    facebook,
    featuredImage,
    instagram,
    title,
    twitter,
    website,
  } = node.frontmatter

  return (
    <Box>
      <Box
        sx={{
          height: [250, null, 300],
          background:
            "linear-gradient(135deg, rgba(0, 0, 0, 0) 10%, RGBA(252, 22, 75, 0.75) 100%)",
        }}
      >
        {featuredImage ? (
          <ImageCard
            sx={{ flexGrow: 1, height: "100%" }}
            overlay={`linear-gradient(135deg, rgba(0, 0, 0, 0) 10%, RGBA(252, 22, 75, 0.75) 100%)`}
            src={featuredImage?.childImageSharp}
            position="50% 20%"
          />
        ) : (
          <Dot />
        )}
      </Box>
      <Box sx={{ my: 3 }}>
        <Heading variant="headings.5">{title}</Heading>
        <Heading variant="headings.5" sx={{ color: "accent" }}>
          {countries?.join(", ")}
        </Heading>
        <Text
          sx={{ fontFamily: "prose", fontSize: 4, color: "text" }}
          dangerouslySetInnerHTML={{ __html: localisedContent.html }}
        />
      </Box>

      {facebook && (
        <Box>
          <span>Facebook:</span>
          <Link to={`https://facebook.com/${facebook}`}>{facebook} ↗</Link>
        </Box>
      )}
      {twitter && <TwitterHandle url={twitter} />}
      {instagram && (
        <Box>
          <span>Instagram:</span>
          <Link to={`https://instagram.com/${instagram}`}>{instagram} ↗</Link>
        </Box>
      )}
      {website && (
        <Box>
          <span>Website:</span>
          <LiteralLink to={website} />
        </Box>
      )}
    </Box>
  )
}
